.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: "Kanit", sans-serif !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  color: #2d2d2d !important;
}

h2 {
  font-family: "Kanit", sans-serif !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #2d2d2d !important;
}

p {
  font-family: "Bai Jamjuree", sans-serif !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #2d2d2d !important;
}

.fab-container {
  bottom: 3vh !important;
  right: 3vw !important;
}

ul.navbar {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
  flex: 1;
}

li.navbar {
  float: left;
}

li.navbar a {
  display: block;
  color: white;
  text-align: center;
  padding: 0px 4px;
  text-decoration: none;
}

li.navbar a:hover {
  background-color: #111;
}